<template>
  <div class="advert">
    <img :src="comObj.imgUrl" alt="" :style="{height:comObj.height+'px'}">
  </div>
</template>

<script>
export default {
  props: ["comObj"],
  data() {
    return {}
  }
};
</script>

<style lang="scss" scoped>
.advert {
  width: 100%;
  padding: 0 10px;
  img {
    width: 100%;
  }
}
</style>
